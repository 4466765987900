/* mada-200normal - latin */
@font-face {
  font-family: 'Mada';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src:
    local('Mada Extra Light '),
    local('Mada-Extra Light'),
    url(/static/media/mada-latin-200.2d209c1f.woff2) format('woff2'), 
    url(/static/media/mada-latin-200.f280d0a4.woff) format('woff'); /* Modern Browsers */
}

/* mada-300normal - latin */
@font-face {
  font-family: 'Mada';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Mada Light '),
    local('Mada-Light'),
    url(/static/media/mada-latin-300.3eca18f2.woff2) format('woff2'), 
    url(/static/media/mada-latin-300.c4405138.woff) format('woff'); /* Modern Browsers */
}

/* mada-400normal - latin */
@font-face {
  font-family: 'Mada';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Mada Regular '),
    local('Mada-Regular'),
    url(/static/media/mada-latin-400.2d420e39.woff2) format('woff2'), 
    url(/static/media/mada-latin-400.b8c40e6c.woff) format('woff'); /* Modern Browsers */
}

/* mada-500normal - latin */
@font-face {
  font-family: 'Mada';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('Mada Medium '),
    local('Mada-Medium'),
    url(/static/media/mada-latin-500.dfece37c.woff2) format('woff2'), 
    url(/static/media/mada-latin-500.8627fe9b.woff) format('woff'); /* Modern Browsers */
}

/* mada-600normal - latin */
@font-face {
  font-family: 'Mada';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
    local('Mada SemiBold '),
    local('Mada-SemiBold'),
    url(/static/media/mada-latin-600.0462ea74.woff2) format('woff2'), 
    url(/static/media/mada-latin-600.b64a9f15.woff) format('woff'); /* Modern Browsers */
}

/* mada-700normal - latin */
@font-face {
  font-family: 'Mada';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Mada Bold '),
    local('Mada-Bold'),
    url(/static/media/mada-latin-700.2474f817.woff2) format('woff2'), 
    url(/static/media/mada-latin-700.77dc66e0.woff) format('woff'); /* Modern Browsers */
}

/* mada-900normal - latin */
@font-face {
  font-family: 'Mada';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Mada Black '),
    local('Mada-Black'),
    url(/static/media/mada-latin-900.c7634d4e.woff2) format('woff2'), 
    url(/static/media/mada-latin-900.fdce3f3f.woff) format('woff'); /* Modern Browsers */
}


